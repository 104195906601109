/**
 * Bio component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

import { rhythm } from "../utils/typography"

require(`./bio.css`)

function BioFull() {
  return (
    <StaticQuery
      query={bioFullQuery}
      render={data => {
        const { author, social } = data.site.siteMetadata
        return (
          <div
            style={{
              marginBottom: rhythm(2.5),
            }}
          >
            <div style={{ display: "flex", flexWrap: "wrap-reverse", marginTop: rhythm(1.0) }}>

              <div class='bio' style={{ flex: "70%" }}>
                <p>My name is Panagiotis (Παναγιώτης, IPA: [panaˈʝotis]) and I’m a Research Scientist at <a href="https://www.isomorphiclabs.com/">Isomorphic Labs</a> working towards Drug Discovery with AI. Before that, I was a DPhil student at the university of Oxford, studying in the area of Autonomous Intelligent Machines and Systems, under the supervision of Dr. Yarin Gal (<a href="https://oatml.cs.ox.ac.uk/">OATML</a>). I am interested in causality, bayesian cybernetics and generative design.<br /><br />In past life, I worked as an ML engineer for companies like <a href="https://www.microsoft.com">Microsoft</a>, <a href="https://autodeskresearch.com/projects/dreamcatcher">Autodesk</a>, <a href="https://brave.com">Brave</a> and co-run my own startup <a href="https://www.filisia.com">Filisia</a>, a company specialized in special-education technology.</p>

                {/* <h4>Contact</h4>
                <p>
                  Wolfson Building,<br/>Parks Road, Oxford OX1 3QD,<br/>United Kingdom<br/>
                  <code>ptigas [at] robots.ox.ac.uk</code>
                </p>
                <div style={{ marginTop: rhythm(0.5) }}>
                  <a href="https://twitter.com/ptigas">Twitter</a>  /  <a href="https://www.dropbox.com/s/z9hsa88ec1p9ec6/ptigas_cv.pdf">CV</a>  /  <a href="https://github.com/ptigas">GitHub</a>
                </div> */}
              </div>

              <div class='bio_avatar' style={{ flex: "30%", textAlign: "center", marginTop: '3px' }}>
                <Image
                  fixed={data.avatar.childImageSharp.fixed}
                  alt={author}
                  style={{
                    minWidth: 200,
                    minHeight: 200,
                    borderRadius: `100%`,
                  }}
                  imgStyle={{
                    borderRadius: `50%`,
                  }}
                />
              </div>

            </div>

            <div style={{ marginTop: rhythm(0.5) }}>
              <a href="https://twitter.com/ptigas">twitter</a>  /  <a href="https://github.com/ptigas">github</a>
            </div>

            <div style={{ marginTop: rhythm(2) }}></div>
            <h3 id="work-experience">Research Interests</h3>

            <p>I'm interested in applications of deep learning in scientific discovery. That includes designing systems that are good at modelling (e.g. inductive biases) or pioneering techniques to enhance explainability and interpretability (e.g. causal discovery) or researching on methods that can optimize the efficiency of experimentation (e.g. Bayesian Optimal Experimental Design) for the rapid discovery of the phenomena of interest.</p>

            < div style={{ marginTop: rhythm(1) }
            }></div>

            <h3 id="work-experience">Work Experience</h3>

            <ul>
              <li><span class="date">2023 - now&nbsp;</span> <span class="what">Research Scientist - <a href="https://www.isomorphiclabs.com/" target="_blank">Isomorphic Labs, Alphabet</a></span></li>
              <li><span class="date">summer 2021</span> <span class="what">PhD Internship - <a href="https://www.microsoft.com/en-us/research/group/deep-reinforcement-learning/" target="_blank">Microsoft Research, Game Intelligence Group, Cambridge</a></span></li>
              <li><span class="date">summer 2020</span> <span class="what">Machine Learning Researcher - <a href="https://frontierdevelopmentlab.org/" target="_blank">NASA Frontier Development Lab (Hosted by SETI)</a></span></li>
              <li><span class="date">2018 - 2019</span> <span class="what">Machine Learning Researcher (Privacy-Preserving ML) - <a href="https://brave.com/" target="_blank">Brave Research</a></span></li>
              <li><span class="date">2017 - 2018</span> <span class="what">Senior Software / ML Engineer - Autodesk Research (<a href="https://autodeskresearch.com/projects/dreamcatcher" target="_blank">Project Dreamcatcher</a>)</span></li>
              <li><span class="date">2016 - 2017</span> <span class="what">CTO - <a href="http://www.filisia.com/" target="_blank">Filisia Interfaces</a></span></li>
              <li><span class="date">2012 - 2015</span> <span class="what">Software Development Engineer II - <a href="https://www.microsoft.com/" target="_blank">Microsoft</a></span></li>
              <li><span class="date">2011 - 2012</span> <span class="what">Software Developer - Buyometrics</span></li>
            </ul>

            <h3 id="teaching--workshops">Teaching / Workshops</h3>

            <ul>
              <li><span class="date">Feb 2018</span> <span class="what"><a href="https://www.ucl.ac.uk/bartlett/" target="_blank">UCL Bartlett school of Architecture</a> - Machine Learning / AI Tutor</span></li>
              <li><span class="date">Jan 2017</span> <span class="what"><a href="https://www.herts.ac.uk/" target="_blank">University of Hertfordshire<a></a> - Introduction to Arduino: Build a self-driving car</a></span></li>
            </ul>


            <h3 id="work-experience">Reviewer / Service</h3>

            <p>ICML, ICLR, NeurIPS</p>

            <h3 id="exhibitions--talks--work-appeared">Exhibitions / Talks / Work Appeared</h3>

            <ul>
              <li><span class="date">Nov 2020</span> <span class="what">Qualcomm, Amsterdam - Research Talk "Can Autonomous Vehicles Identify, Recover From, and Adapt to Distribution Shifts?" </span></li>
              <li><span class="date">Oct 2020</span> <span class="what">ETH, Zurich - AIA Symposium, Research talk "Towards an Autonomous Architecture"</span></li>
              <li><span class="date">Jan 2018</span> <span class="what">Research Values - panel talk, Transmediale, Berlin</span></li>

              <li><span class="date">Jan 2018</span> <span class="what">Research Values - panel talk, Transmediale, Berlin</span></li>
              <li><span class="date">Jan 2016</span> <span class="what">Filisia Interfaces, Bett Show, London</span></li>
              <li><span class="date">Oct 2015</span> <span class="what">Down To Earth, 66th International Astronautical Congress, Jerusalem</span></li>
              <li><span class="date">Aug 2015</span> <span class="what">Voice Booth, Institute of Contemporary Art, Miami</span></li>
              <li><span class="date">Mar 2015</span> <span class="what">Parallel Chance, FutureFest, London</span></li>
              <li><span class="date">Mar 2015</span> <span class="what">Parallel Chance, Tomorrows Transaction forum , London</span></li>
              <li><span class="date">Sep 2014</span> <span class="what"><a href="http://caveofsounds.com" target="_blank">Cave of Sounds</a>, 90db Festival, Rome</span></li>
              <li><span class="date">Mar 2014</span> <span class="what">Voice Booth, SXSW (South by Southwest) 2014 Interactive Awards, Austin, Texas</span></li>
              <li><span class="date">Apr 2014</span> <span class="what">Down To Earth, International Space Apps Challenge led by NASA, Dana Center, London.</span></li>
              <li><span class="date">Nov 2013</span> <span class="what"><a href="http://caveofsounds.com" target="_blank">Cave of Sounds</a>, Watermans’ Digital Art &amp; Performance Weekender, London</span></li>
              <li><span class="date">Sep 2013</span> <span class="what"><a href="http://caveofsounds.com" target="_blank">Cave of Sounds</a>, V&amp;A, Digital Design Weekend, part of London Design Festival</span></li>
              <li><span class="date">Aug 2013</span> <span class="what"><a href="http://caveofsounds.com" target="_blank">Cave of Sounds</a>, Hack the Barbican, London</span></li>
              <li><span class="date">Apr 2013</span> <span class="what">International Space Apps Challenge led by NASA, Google Campus, London</span></li>
            </ul>

          </div >
        )
      }}
    />
  )
}

const bioFullQuery = graphql`
  query BioFullQuery {
    avatar: file(absolutePath: { regex: "/avatar.png/" }) {
      childImageSharp {
        fixed(width: 200, height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
        }
      }
    }
  }
`

export default BioFull
